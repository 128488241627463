.topo-q-linha {
    border-bottom: 1px solid #D2D2D2;
}

.topo-q-coluna {
    border-left: 1px solid #D2D2D2;
}

.blocos {
    border-bottom: 1px solid #D2D2D2;
    margin-bottom: 8px;
}

.topo-icons {
    font-size: x-large;
}