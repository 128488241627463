.customLinks{
    color: blue;
    cursor: pointer;
}

html,
body,
#root{
  height: 100%;
  background-color: rgb(50, 50, 50);
}